import './components/Landing'
import './App.css';
import Landing from './components/Landing';
import Presentation from './components/Presentation';
import Realisations from './components/Realisations';
import Prestations from './components/Prestations';
import Contact from './components/Contact'
import Footer from './components/Footer'

function App() {
  return (
	<div className='App'>
		<Landing></Landing>
		<Presentation></Presentation>
		<Realisations></Realisations>
		<Prestations></Prestations>
		<Contact></Contact>
		<Footer></Footer>
	</div>
  );
}

export default App;
