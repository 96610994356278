import '../App.css';
import './Footer.css'

function Footer() {
  return (
    <>
        <div className='footer-background'>
            <div className="p-2"> Créé par Loïc Saunier - 06/2023 </div>
            <div className="p-2"> © Tout droits réservés </div>
        </div>
      
    </>
  );
}

export default Footer;
