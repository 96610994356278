import '../App.css';
import './Landing.css'
import Stack from 'react-bootstrap/Stack';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Typewriter from 'typewriter-effect';

import videoBackground from '../assets/background_Landing_PartialOrange.mp4'

function Landing() {
  return (
    <>

        <div className="video-background">

        <Navbar className='navbar' variant='dark' expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="#home" className='navbar-links'>Accueil</Nav.Link>
                  <Nav.Link href="#presentation" className='navbar-links'>Présentation</Nav.Link>
                  <Nav.Link href="#realisations" className='navbar-links'>Réalisations</Nav.Link>
                  <Nav.Link href="#prestations" className='navbar-links'>Tarifs</Nav.Link>
                  <Nav.Link href="#contact" className='navbar-links'>Contact</Nav.Link>
                </Nav>
              </Navbar.Collapse>
        </Navbar>

            <video autoPlay loop muted>
                <source src={videoBackground} type="video/mp4" />
            </video>

            <Stack className='titleContainer' gap={2} id="home">

                <h1> SOS Nuisibles 67</h1>
                  
                <div className='text-animation-container'>
                  <h2> Nos activités : </h2>
                  <Typewriter className='text-animation'
                    options={{
                      strings: ['Destruction de nids de guêpes et frelons', 'Dératisation','Nettoyage et désinfection', 'Dépigeonnage', 'Multiservices'],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </div>
                

            </Stack>
        </div>       
    </>
  );
}

export default Landing;
