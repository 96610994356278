import '../App.css';
import './Contact.css'

import Table from 'react-bootstrap/Table';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import { AiFillPhone, AiFillMail } from "react-icons/ai";


function Contact() {
  return (
    <>
      <div className='contact-title-container'>
        <h1 className='contact-title'>Comment nous contacter ?</h1>
      </div>
      <Stack className='background' direction="horizontal" id="contact">

        <Table striped bordered hover size="sm" className='elem p-2 day-table'>
                <thead>
                  <tr>
                    <th>Jour</th>
                    <th>Horaires</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lundi</td>
                    <td>8h - 19h</td>
                  </tr>
                  <tr>
                    <td>Mardi</td>
                    <td>8h - 19h</td>
                  </tr>
                  <tr>
                    <td>Mercredi</td>
                    <td>8h - 19h</td>
                  </tr>
                  <tr>
                    <td>Jeudi</td>
                    <td>8h - 19h</td>
                  </tr>
                  <tr>
                    <td>Vendredi</td>
                    <td>8h - 19h</td>
                  </tr>
                  <tr>
                    <td>Samedi</td>
                    <td>Fermé</td>
                  </tr>
                  <tr>
                    <td>Dimanche</td>
                    <td>Fermé</td>
                  </tr>
                  
                </tbody>
        </Table>

        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2640.5768183962864!2d7.495796076817936!3d48.56050012162332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796aff4f3eb59c3%3A0xf588e07b6ba06ce7!2sSOS%20Nuisibles%2FGu%C3%AApes%2067!5e0!3m2!1sfr!2sfr!4v1688375784500!5m2!1sfr!2sfr" 
          title='Map'
          height="400"
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade"
          className="elem">
        </iframe>

        
        <Stack className='infos elem p-2' gap={1}>
            <h1 className="p-2 name">Regnouf Florian</h1>
            <div className="p-2 phone">
                <span>07 86 04 79 06</span>
                <a href="tel:+33786047906">
                  <Button variant="outline-primary" className='phone-btn'>
                      <AiFillPhone/>
                  </Button>
                </a>
                
            </div>
            <div className="p-2 mail">
                <span>contact@sosnuisibles67.fr</span>
                <a href="mailto:contact@sosnuisibles67.fr">
                  <Button variant="outline-primary" className='mail-btn'>
                      <AiFillMail/>
                  </Button>
                </a>
                
            </div>
            <div className="p-2 itinary">
                <span>4A rue du Buehl - Avolsheim</span>
            </div>
        </Stack>

    </Stack>

    </>
  );
}

export default Contact;
