import '../App.css';
import './Presentation.css'
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import image from '../assets/florian.jpeg'

function Presentation() {
  return (
    <div className='presentation-background' id="presentation">
            <Row>

                <Col sm={3}>
                    <Image src={image} roundedCircle className='img'/>
                </Col>

                <Col sm={8}>
                    <Stack className='presentation' gap={2}>
                        <h1 className='presentation-title'> Qui sommes nous ?</h1>
                        <p className='presentation-desc'>
                        SOS Nuisibles 67 est une société spécialisée dans la lutte contre les nuisibles. Notre entreprise est dévouée à offrir des services de haute qualité pour résoudre les problèmes liés aux insectes, rongeurs et autres organismes nuisibles.                         
                        </p>
                        <p className='presentation-desc'>
                        Nos techniciens sont formés pour identifier, évaluer et traiter les infestations de manière professionnelle. Nous utilisons des méthodes et des produits respectueux de l'environnement, conformes aux normes en vigueur, afin d'assurer la sécurité de nos clients, de leurs familles et de leurs animaux de compagnie. 
                        </p>
                        <p className='presentation-desc'>
                        Chez SOS Nuisibles 67, nous comprenons également l'importance de la prévention pour éviter toute récidive. Nous offrons des conseils personnalisés pour vous aider à identifier les causes sous-jacentes des infestations et mettons en place des mesures préventives adaptées à votre situation.                        
                        </p>
                        <p className='presentation-desc motto'>
                        Faites appel à SOS Nuisibles 67 pour retrouver un environnement sain et paisible dans votre domicile ou votre lieu de travail.
                        </p>
                    </Stack>
                </Col>

            </Row>      
    </div>
  );
}

export default Presentation;
