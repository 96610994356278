import '../App.css';
import './Prestations.css'

import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';


import React, { useEffect, useState } from 'react';



function Prestations() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update the windowWidth state when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
        
      <div className='background-prestations' id="prestations">
        <div className='background-opacity'></div>

          <div className='prestations-title'>
            <h1>Nos tarifs</h1>
          </div>

          {windowWidth > 767 ? (<Table striped bordered hover size="sm" responsive>
              <thead>
                <tr>
                  <th>Destruction de nids de guêpes et de frelons</th>
                  <th>Prix</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nid à hauteur d'Homme</td>
                  <td>90 €</td>
                </tr>
                <tr>
                  <td>Nid avec échelle jusqu'à 6m</td>
                  <td>120 €</td>
                </tr>
                <tr>
                  <td>Nid en toiture</td>
                  <td>150 €</td>
                </tr>
                <tr>
                  <td>Nid spécifique</td>
                  <td>Sur devis</td>
                </tr>
              </tbody>
          </Table>
        ) : (
          <>
          <h2 className='prestations-subtitle'>Destruction de nids de guêpes et frelons</h2>
          <div className='card-container'>
            <Card className='card-custom'>
              <Card.Body className='card-body-custom'>
                <Card.Title className='card-title-custom'>Nid à hauteur d'Homme</Card.Title>
                <Card.Text className='card-text-custom'>
                  90€
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className='card-custom'>
              <Card.Body className='card-body-custom'>
                <Card.Title className='card-title-custom'>Nid avec échelle jusqu'à 6m</Card.Title>
                <Card.Text className='card-text-custom'>
                  120€
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className='card-custom'>
              <Card.Body className='card-body-custom'>
                <Card.Title className='card-title-custom'>Nid en toiture</Card.Title>
                <Card.Text className='card-text-custom'>
                  150€
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className='card-custom'>
              <Card.Body className='card-body-custom'>
                <Card.Title className='card-title-custom'>Nid spécifique</Card.Title>
                <Card.Text className='card-text-custom'>
                  Sur devis
                </Card.Text>
              </Card.Body>
            </Card>
        </div>
        </>
        )}  
      
      </div>
    </>
  );
}

export default Prestations;
